import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import DefaultLayout from "../layouts/defaultLayout"

const Volunteering = props => {
  const data = useStaticQuery(graphql`
    query Volunteering {
      allContentfulVolunteering {
        nodes {
          title
          atAGlance
          link
          category
          locations
        }
      }
    }
  `)
  if (data.errors) {
    console.log(data.errors)
    return
  }

  const nodes = data.allContentfulVolunteering.nodes
  const volunteeringList = nodes.map(nodes => (
    <tr>
      <td data-label="Name">{nodes.title}</td>
      <td data-label="Category">{nodes.category}</td>
      <td data-label="Description">
        <div className="volunteering-desc">{nodes.atAGlance}</div>
      </td>
      <td data-label="Location">{nodes.locations}</td>
      <td data-label="Link">
        <a href={nodes.link}>Join</a>
      </td>
    </tr>
  ))

  return (
    <DefaultLayout>
      <div style={{ marginTop: "2rem" }}>
        <h1>Volunteer for our climate!</h1>
        <h2>Our curated list of impactful volunteering opportunities.</h2>
        <hr style={{ background: "#01b76b", width: "75%", height: "5px" }} />
        <table className="volunteering">
          <thead>
            <td>
              <strong>Name</strong>
            </td>
            <td>
              <strong>Category</strong>
            </td>
            <td>
              <strong>Description</strong>
            </td>
            <td>
              <strong>Location</strong>
            </td>
            <td>
              <strong>Link</strong>
            </td>
          </thead>
          <tbody>{volunteeringList}</tbody>
        </table>
      </div>
    </DefaultLayout>
  )
}

export default Volunteering
